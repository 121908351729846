'use client';

import Link from 'next/link';
import { useTranslations } from 'next-intl';

import LocaleSwitcher from '@/components/LocaleSwitcher';
import { buttonVariants } from '@/components/ui/button';
import { CenteredMenu } from '@/features/landing/CenteredMenu';
import { Section } from '@/features/landing/Section';
import { useScroll } from '@/hooks/use-scroll';

import { Logo } from './Logo';

const Navbar = () => {
  const t = useTranslations('Navbar');
  const scrolled = useScroll(50);
  const scroll = false;
  return (
    <Section
      className={`sticky top-0 z-40 flex w-full justify-center bg-background/60 backdrop-blur-xl transition-all ${
        scroll ? (scrolled ? 'border-b' : 'bg-transparent') : 'border-b'
      }`}
    >
      <CenteredMenu
        logo={<Logo />}
        rightMenu={
          <>
            <li>
              <LocaleSwitcher />
            </li>
            <li>
              <Link href="/sign-in">{t('sign_in')}</Link>
            </li>
            <li>
              <Link className={buttonVariants()} href="/sign-up">
                {t('sign_up')}
              </Link>
            </li>
          </>
        }
      >
        <li>
          <Link href="/products ">{t('product')}</Link>
        </li>

        <li>
          <Link href="/documentation">{t('docs')}</Link>
        </li>

       {/*  <li>
          <Link href="/blog">{t('blog')}</Link>
        </li>

        <li>
          <Link href="/community">{t('community')}</Link>
        </li>

        <li>
          <Link href="/company">{t('company')}</Link>
        </li>
         <li>
          <Link href="/partner">{t('partner')}</Link>
        </li> */}
      </CenteredMenu>
    </Section>
  );
};

export { Navbar };
